import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {Box} from "@material-ui/core";
import './Services.Module.css';
import SectionWithLine from "../UI/Title";
import ActiveCursor from "../../assets/icon/cursor-active.svg";
import colors from "../../theme/default";
import StronyWWWImg from '../../assets/image/services/www.png';
import SEOImg from '../../assets/image/services/seo.png';
import SMMImg from '../../assets/image/services/smm.png';
import FilmovanieImg from '../../assets/image/services/Fotografia_Filmowanie.png';
import TlumaczeniaImg from '../../assets/image/services/Tłumaczenia.png';
import SzkoleniaImg from '../../assets/image/services/Szkolenia.png';
import DefaultCard from '../../assets/image/services/DefaultCard.png';
import EventImg from '../../assets/image/services/Event.png';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: `${colors.mineShaft}`,
        borderRadius: '40px 40px 0 0',
        padding: '100px 40px 0 40px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 20px 0 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 150px 0 150px'
        }
    },
    title: {
        color: `${colors.magnolia}`,
        fontFamily: 'Montserrat',
        fontSize: '20px',
        paddingBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    accordion: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: `${colors.magnolia}`,
        fontFamily: 'Montserrat',
        borderBottom: '1px solid #fcfbff',
        borderRadius: '0',
        content: 'none',
    },
    accordionSummary: {
        boxShadow: 'none',
    },
    accordionDetails: {
        boxShadow: 'none',
    },
    image: {
        width: '45%',
        borderRadius: '20px',
        height: '100%',
        objectFit: 'cover',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'all 1s ease-in-out',
        animation: 'fade-in ease 3s',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            // width: '100%',
            // height: '230px',
            // margin: '10px',
        },
        '&:hover': {
            transition: 'all 1s ease',
        },
        'fade-in': {
            animation: 'fadeIn ease 3s',
            '-webkit-animation': 'fadeIn ease 3s',
            '-moz-animation': 'fadeIn ease 3s',
            '-o-animation': 'fadeIn ease 3s',
            '-ms-animation': 'fadeIn ease 3s',
        },
    },

    heading: {
        fontSize: '32px',
        fontFamily: 'Montserrat',
        letterSpacing: '4%',
        color: `${colors.magnolia}`,
        textTransform: 'uppercase',
        cursor: `url(${ActiveCursor}) 11 11, pointer`,
        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,
        },
        '&.selected': {
            color: `${colors.smalt}`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    toggleContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap-reverse',
            justifyContent: 'center',
        },
    },
    descriptionText: {
        fontSize: '20px',
        fontFamily: 'Montserrat',
        color: `${colors.magnolia}`,
        lineHeight: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    gridAccordion: {
        padding: '0',
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
    },
}));

const ServiceBlock = () => {
    const classes = useStyles();
    const [selectedService, setSelectedService] = useState('');
    const [selected, setSelected] = useState(null);

    const handleAccordionClick = (index) => {
        setSelected(index);
    };

    const handleServiceSelect = (service) => {
        setSelectedService(service);
    };

    const services = [
        {
            title: 'Strony WWW',
            description: 'Zapewniamy kompleksową usługę tworzenia stron internetowych, dostosowaną do Twoich potrzeb. Nasz zespół doświadczonych projektantów i programistów zajmie się wszystkim - od stworzenia strony od podstaw, przez projekt graficzny, aż po responsywny Landing Page, który przyciągnie uwagę Twoich klientów. Dodatkowo, możemy uzupełnić Twoje istniejące strony o nowe treści i funkcje, abyś mógł stale rozwijać swoją obecność online. Dzięki naszej obsłudze technicznej stron nie musisz się martwić o problemy techniczne - z nami Twoja strona będzie działać sprawnie i bezawaryjnie. Skup się na swoim biznesie, a my zajmiemy się resztą!',
            image: StronyWWWImg,
        },
        {
            title: 'SEO',
            description: 'Osiągnij wyższe pozycje w wynikach wyszukiwania dzięki naszej usłudze SEO! Nasz zespół ekspertów zajmuje się optymalizacją Twojej strony internetowej, by była bardziej przyjazna dla wyszukiwarek. Dzięki zastosowaniu zaawansowanych technik SEO, w tym optymalizacji treści, analizy słów kluczowych i link buildingu, zwiększymy widoczność Twojej strony w sieci. Przyciągniesz nowych użytkowników, zwiększysz ruch na stronie i wzmocnisz swoją pozycję w branży. Postaw na skuteczną strategię SEO i osiągnij sukces online z naszą pomocą!',
            image: SEOImg,
        },
        {
            title: 'SMM',
            description: 'Rozwijaj swoją markę i buduj silną obecność w mediach społecznościowych dzięki naszej usłudze SMM (Social Media Marketing)! Nasz zespół specjalistów pomoże Ci skutecznie zarządzać Twoimi profilami na platformach społecznościowych. Dzięki naszym strategiom i narzędziom SMM, dotrzesz do swojej grupy docelowej, zbudujesz lojalność klientów i zwiększysz świadomość marki. Tworzymy spersonalizowane treści, zarządzamy kampaniami reklamowymi, monitorujemy wyniki i dostarczamy Ci cenne analizy. Skorzystaj z potęgi mediów społecznościowych i zwiększ swoje sukcesy biznesowe z naszą usługą SMM!',
            image: SMMImg,
        },
        {
            title: 'Fotografia / Filmowanie ',
            description: 'Nasz zespół utalentowanych fotografów i operatorów filmowych doskonale rozumie znaczenie estetyki, narracji i emocji w tworzeniu wspaniałych fotografii i filmów. Niezależnie od tego, czy potrzebujesz sesji fotograficznej na specjalne wydarzenie, takie jak ślub, urodziny, czy też chcesz uwiecznić ważne momenty w życiu swojej firmy, dostosujemy się do Twoich potrzeb i oczekiwań. Nasza usługa obejmuje nie tylko sesję fotograficzną, czy filmowanie, ale także kompleksową obróbkę, edycję i postprodukcję.',
            image: FilmovanieImg,
        },
        {
            title: 'Event',
            description: 'Nasza firma specjalizuje się w kompleksowym organizowaniu i prowadzeniu eventów, tworząc niezapomniane i niepowtarzalne doświadczenia dla naszych klientów. Dążymy do doskonałości w każdym szczególe, angażując naszą kreatywność, pasję i profesjonalizm. Jesteśmy gotowi, aby zrealizować Twoje założenia i cele. Skontaktuj się z nami, aby omówić swoje potrzeby i rozpocząć przygotowania do niezapomnianego wydarzenia.',
            image: EventImg,
        },
        {
            title: 'Szkolenia',
            description: 'Oferujemy usługę prowadzenia szkoleń, które pozwolą rozwijać i doskonalić umiejętności twoich pracowników. Nasze szkolenia są interaktywne oraz dostosowane do potrzeb i celów naszych klientów. Pomożemy udoskonalić, twoją współpracę z obcokrajowcami, poprzez naukę mechanizmów kooperacji m.in.: z Ukrainą. Pomożemy wdrożyć twoich pracowników w procesy obsługi twoich kont społecznościowych oraz pokażemy Ci metody na skuteczne zarządzanie przedsiębiorstwem. Nasze szkolenia są oparte na najnowszych trendach i najlepszych praktykach, aby uczestnicy mogli zdobywać rzeczowe umiejętności i wiedzę.',
            image: SzkoleniaImg,
        },
        {
            title: 'Tłumaczenia',
            description: 'Świadczymy profesjonalne usługi tłumaczeniowe, gwarantujemy precyzyjne i wiernie oddane przekłady. Oferujemy tłumaczenia zarówno pisemne, jak i ustne, w różnych kombinacjach językowych Angielsko-Polsko-Ukraińskich. Obsługujemy dokumenty, teksty, strony internetowe, prezentacje, instrukcje, tłumaczenia poświadczone, a także zapewniamy tłumaczenia ustne na konferencjach, spotkaniach biznesowych, czy negocjacjach. Działamy szybko i skutecznie, dbając o terminowość i jakość tłumaczeń. Zapewniamy pełną poufność i bezpieczeństwo danych klientów.',
            image: TlumaczeniaImg,
        },
    ];

    return (
        <div className={classes.root} id="services">
            <SectionWithLine title="Usługi" lineColor="#FcFbFF" textColor="rgb(252, 251, 255)"/>
            <Box>
                <Typography className={classes.title}>
                    Oferujemy wiele usług, które wypromują twoją markę, pomogą w dotarciu do nowych
                    klientów i osiągnięciu sukcesu na rynku. Dzięki naszemu doświadczeniu i wiedzy w
                    dziedzinie marketingu jesteśmy w stanie opracować strategie skrojone na miarę,
                    dostosowane do indywidualnych potrzeb i celów naszych klientów. Zapewniamy
                    kompleksowe rozwiązania marketingowe, które pomogą Ci rozwinąć Twój biznes.
                </Typography>
            </Box>
            <Grid container spacing={2} className={classes.toggleContainer}>
                <img
                    src={
                        selectedService
                            ? services.find((service) => service.title === selectedService).image
                            : DefaultCard
                    }
                    alt="Изображение услуги"
                    className={classes.image}
                />
                <Grid item xs={12} md={6} className={classes.gridAccordion}>
                    {services.map((service, index) => (
                        <Accordion
                            key={service.title}
                            index={index}
                            className={classes.accordion}
                            expanded={selectedService === service.title}
                            onChange={() => handleServiceSelect(service.title)}
                        >
                            <AccordionSummary
                                className={classes.accordionSummary}
                                onClick={() => handleAccordionClick(index)}
                                selected={selected === index}
                            >
                                <Typography className={`${classes.heading} ${selected === index ? 'selected' : ''}`}>
                                    {service.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={classes.descriptionText}>
                                    {service.description}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};

export default ServiceBlock;
