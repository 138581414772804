import React from "react";

function FacebookLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            fill="none"
            viewBox="0 0 33 32"
        >
            <rect width="32.385" height="32" fill="#F8F8F8" rx="10"></rect>
            <path
                fill="#010002"
                d="M17.742 26v-9.122h3.06l.46-3.556h-3.52v-2.27c0-1.03.284-1.731 1.762-1.731l1.881-.001V6.14A25.514 25.514 0 0018.643 6c-2.715 0-4.573 1.657-4.573 4.7v2.622H11v3.556h3.07V26h3.672z"
            ></path>
        </svg>
    );
}

export default FacebookLogo;