import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Background from './Background.png';
import StyledButton from "../Button";
import ActiveCursor from "../../../assets/icon/cursor-active.svg";
import colors from "../../../theme/default";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(5px)',
    },
    paper: {
        width: '80%',
        height: '90%',
        position: 'relative',
        padding: '0',
        outline: 'none',
        borderRadius: '32px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            borderRadius: '0',
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
            height: '80%',
        },
    },
    backgroundImageBlock: {
        height: '50%',
        borderRadius: '32px 32px 0 0',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: '50%',
            backgroundPosition: 'center',
            borderRadius: '0',
        },
    },
    modalSubtitle: {
        marginBottom: theme.spacing(4),
    },
    modalCloseButton: {
        padding: '0',
        color: `${colors.magnolia}`,
        cursor: `url(${ActiveCursor}) 11 11, pointer`,
        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,
        },
    },
    modalContent: {
        height: '50%',
        display: 'flex',
        gap: '30px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '0 0 32px 32px',
        padding: '20px 20px 24px',
        backgroundColor: `${colors.mineShaft}`,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px 24px',
            borderRadius: '0',
        },
    },
    closeIconBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
    },
    modalText: {
        [theme.breakpoints.down('sm')]: {
            overflow: 'scroll',
            maxHeight: '100%',
        },

    },
    moreDescription: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: `${colors.magnolia}`,
        textAlign: 'left',
    },
}));

function AvatarModal() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    // useEffect(() => {
    //     const body = document.querySelector('body');
    //     if (open) {
    //         body.style.overflowY = 'hidden';
    //     } else {
    //         body.style.overflowY = 'auto';
    //     }
    // }, [open]);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <StyledButton onClick={handleOpen} text="Więcej" type="button" mt="20px" />
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Paper className={classes.paper}>
                        <Box className={classes.backgroundImageBlock}>
                            <Box className={classes.closeIconBlock}>
                                <Box className={classes.modalTitle}>
                                    <Typography style={{
                                        fontFamily: 'Syncopate-Regular',
                                        fontSize: '20px',
                                        color: `${colors.magnolia}`,
                                        fontWeight: '700'
                                    }}>
                                        Serhii Zinchenko
                                    </Typography>
                                </Box>
                                <IconButton
                                    className={classes.modalCloseButton}
                                    aria-label="close"
                                    onClick={handleClose}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                        <Box className={classes.modalContent}>
                            <Box className={classes.modalText}>
                                <Typography className={classes.moreDescription}>
                                    Cześć! Jestem założycielem firmy, która specjalizuje się w tworzeniu stron
                                    internetowych, organizowaniu eventów biznesowych oraz prowadzeniu kampanii
                                    promocyjnych. Od lat pasjonuję się branżą kreatywną i sukcesywnie rozwijam swoje
                                    umiejętności, aby dostarczać klientom wyjątkowe rozwiązania na rynku. Cenię sobie
                                    jakość i profesjonalizm, a moją misją jest pomaganie klientom w osiągnięciu sukcesu
                                    w biznesie. Zawsze podchodzę do każdego projektu z pasją i zaangażowaniem, dążąc do
                                    osiągnięcia jak najlepszych wyników. Jako kreatywny partner biznesowy, zawsze jestem
                                    elastyczny i dostosowuję się do wymagań klientów. Posiadam umiejętności w
                                    zarządzaniu, co pozwala na sprawną realizację zleceń i dotrzymanie harmonogramu.
                                    Jeśli szukasz specjalisty, który zawsze będzie działał w Twoim najlepszym interesie
                                    i dostarczy kompleksowe rozwiązania dla Twojej firmy, to jestem tu, aby Ci pomóc.
                                    Razem osiągniemy sukces!
                                </Typography>
                            </Box>
                            <StyledButton onClick={handleClose} text="Widzę" type="button"/>
                        </Box>
                    </Paper>
                </Fade>
            </Modal>
        </div>
    );
}

export default AvatarModal;
