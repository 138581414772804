import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Box, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import './ContactForm.css';
import StyledButton from '../Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ActiveCursor from '../../../assets/icon/cursor-active.svg';
import colors from '../../../theme/default';

const useStyles = makeStyles((theme) => ({
    checkBoxDescription: {
        color: colors.magnolia,
        fontFamily: 'Montserrat',
        fontSize: '12px',
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px',
        },
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        marginBottom: '20px',
        paddingTop: '20px',
    },
    checkBox: {
        cursor: `url(${ActiveCursor}) 11 11, pointer`,
        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,
        },
    },
    error: {
        color: '#f44336',
        margin: '0 14px 0 14px',
        fontSize: '0.75rem',
    },
    formControlLabel: {
        alignItems: 'flex-start',
        marginRight: '0',
    },
}));

const initialValues = {
    name: '',
    email: '',
    message: '',
    checkbox: false,
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Wpisz swoje imię'),
    email: Yup.string().email('Proszę podać poprawny adres e-mail').required('Wprowadź swój email'),
    message: Yup.string().required('Wpisz swoją wiadomość'),
    checkbox: Yup.boolean().oneOf([true], 'To pole jest wymagane').required('To pole jest wymagane'),
});

const ContactForm = () => {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [isFormFilled, setIsFormFilled] = useState(false);

    const handleFormChange = (values) => {
        const isFormValid = Object.values(values).every((val) => Boolean(val));
        setIsFormFilled(isFormValid);
    };

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        if (values.checkbox) {
            setTimeout(() => {
                console.log(JSON.stringify(values, null, 2));
                resetForm();
                setSubmitting(false);
            }, 500);
        }
    };

    return (
        <Box style={{ width: '100%' }}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange>
                {({ isSubmitting, isValid, errors, touched, values }) => (
                    <form action="https://formspree.io/f/xoqovgge" method="POST" onChange={() => handleFormChange(values)}>
                        <Box className={classes.inputWrapper}>
                            <Box sx={{ flex: 1 }}>
                                <Field name="name">
                                    {({ field, form }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label="Imię"
                                            variant="outlined"
                                            error={form.errors.name && form.touched.name}
                                            helperText={form.touched.name && form.errors.name}
                                        />
                                    )}
                                </Field>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Field name="email">
                                    {({ field, form }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label="Email"
                                            variant="outlined"
                                            error={form.errors.email && form.touched.email}
                                            helperText={form.touched.email && form.errors.email}
                                        />
                                    )}
                                </Field>
                            </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Field name="message">
                                {({ field, form }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Wiadomość"
                                        variant="outlined"
                                        multiline
                                        minRows={4}
                                        error={form.errors.message && form.touched.message}
                                        helperText={form.touched.message && form.errors.message}
                                    />
                                )}
                            </Field>
                        </Box>
                        <Box style={{ marginBottom: '16px' }}>
                            <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Field name="checkbox" type="checkbox" className={classes.checkBox}>
                                    {({ field, form }) => (
                                        <FormControlLabel
                                            className={classes.formControlLabel}
                                            control={<Checkbox
                                                {...field}
                                                color="primary"
                                                error={form.errors.checkbox && form.touched.checkbox} />}
                                            label={
                                                <Typography className={classes.checkBoxDescription}>
                                                    Oświadczam, że zapoznałem się z polityką prywatności wydaną przez administratorów danych, zgodnie z artykułami 13 i 14 Rozporządzenia UE 2016/679 oraz w pełni zrozumiałem treść. W tym celu, zgodnie z art. 7 Rozporządzenia UE 2016/679, wyrażam zgodę, w sposób wolny i jednoznaczny, na przetwarzanie Danych Osobowych do celów wskazanych we wspomnianej wyżej polityce prywatności.
                                                </Typography>
                                            }
                                        />
                                    )}
                                </Field>
                            </Box>
                            {touched.checkbox && errors.checkbox && <span className={classes.error}>{errors.checkbox}</span>}
                        </Box>
                        <StyledButton type="submit" disabled={isSubmitting || !isValid} text="Wyślij" width="100%" />
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default ContactForm;

