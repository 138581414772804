import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import StyledButton from '../Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import ActiveCursor from "../../../assets/icon/cursor-active.svg";
import colors from "../../../theme/default";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '40px',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
    },
    modalContent: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: '10px 40px',
        backgroundColor: `${colors.mineShaft}`,
        borderRadius: '20px',
        outline: 'none',
        textAlign: 'center',
        pointerEvents: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '80%',
        },
    },
    description: {
        color: `${colors.magnolia}`,
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '19.5px',
        letterSpacing: '2%',
        textAlign: 'justify',
    },
    modalCloseButton: {
        padding: '0',
        color: `${colors.magnolia}`,
        cursor: `url(${ActiveCursor}) 11 11, pointer`,
        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,
        },
    },
    closeIconBlock: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '5px',
    },
    titleCookie: {
        color: `${colors.magnolia}`,
        fontSize: '24px',
        fontWeight: '700',
        lineHeight: '29.26px',
        letterSpacing: '2%',
    },
}));

const CookieModal = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
        setOpen(!hasAcceptedCookies);
    }, []);

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        handleClose(null, null);
    };

    return (
        <Modal open={open} onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
                handleClose();
            }
        }} className={classes.modal} BackdropProps={{ invisible: true }}>
            <div className={classes.modalContent}>
                <Box className={classes.closeIconBlock}>
                    <Typography className={classes.titleCookie}>
                        Umowy użytkownika i pliki cookie
                    </Typography>
                    <IconButton
                        className={classes.modalCloseButton}
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography className={classes.description}>
                    Witam!!! Korzystamy z plików cookies. Celem jest marketing i analiza ruchu. Więcej możesz dowiedzieć się tutaj.? 🍪🍪🍪
                </Typography>
                <StyledButton text="Zgadzam się" padding="10px" onClick={handleAccept} />
            </div>
        </Modal>

    );
};

export default CookieModal;

