import React from "react";

function InstagramLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            fill="none"
            viewBox="0 0 33 32"
        >
            <rect width="31.996" height="32" x="0.386" fill="#F8F8F8" rx="10"></rect>
            <path
                fill="#000"
                d="M26.331 11.88c-.047-1.063-.219-1.793-.465-2.426A4.88 4.88 0 0024.71 7.68a4.923 4.923 0 00-1.77-1.153c-.637-.246-1.364-.418-2.426-.464C19.443 6.012 19.104 6 16.388 6s-3.056.012-4.122.059c-1.063.046-1.793.218-2.426.464A4.88 4.88 0 008.066 7.68a4.924 4.924 0 00-1.153 1.77c-.246.637-.418 1.363-.465 2.426-.05 1.07-.062 1.41-.062 4.126 0 2.715.011 3.055.058 4.122.047 1.063.22 1.793.465 2.426a4.931 4.931 0 001.157 1.774c.5.508 1.105.902 1.77 1.152.636.247 1.363.419 2.426.465 1.066.047 1.406.059 4.122.059 2.715 0 3.055-.012 4.122-.059 1.063-.046 1.793-.218 2.426-.464a5.116 5.116 0 002.926-2.927c.246-.637.418-1.364.465-2.426.047-1.067.059-1.407.059-4.122s-.004-3.055-.05-4.122zm-1.801 8.166c-.043.977-.207 1.504-.344 1.855a3.318 3.318 0 01-1.899 1.9c-.351.136-.883.3-1.855.343-1.055.047-1.372.059-4.04.059-2.669 0-2.99-.012-4.04-.059-.977-.043-1.505-.207-1.856-.344a3.077 3.077 0 01-1.149-.746 3.11 3.11 0 01-.746-1.148c-.137-.352-.3-.883-.344-1.856-.047-1.055-.058-1.372-.058-4.04 0-2.669.011-2.99.058-4.04.043-.977.207-1.504.344-1.856a3.04 3.04 0 01.75-1.149A3.104 3.104 0 0110.5 8.22c.351-.137.883-.3 1.856-.344 1.055-.047 1.371-.058 4.04-.058 2.672 0 2.988.011 4.04.058.976.043 1.504.207 1.855.344.434.16.828.414 1.149.746.332.325.586.715.746 1.15.137.35.301.882.344 1.855.047 1.055.059 1.371.059 4.04 0 2.668-.012 2.98-.059 4.036z"
            ></path>
            <path
                fill="#000"
                d="M16.388 10.864a5.139 5.139 0 000 10.276 5.139 5.139 0 000-10.276zm0 8.47a3.333 3.333 0 110-6.666 3.333 3.333 0 010 6.667zm6.54-8.673a1.2 1.2 0 11-2.399 0 1.2 1.2 0 012.4 0z"
            ></path>
        </svg>
    );
}

export default InstagramLogo;