import React from "react";
import {Box, Typography} from "@material-ui/core";
import SectionWithLine from "../UI/Title";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as ExpertizaIcon} from "../../assets/icon/Reason/Expertyza/Expertyza.svg";
import {ReactComponent as ZintegrowaneIcon} from "../../assets/icon/Reason/Zintegrowane/Zintegrowane.svg";
import {ReactComponent as AnalyticsIcon} from "../../assets/icon/Reason/Analitycs/Analitycs.svg";
import {ReactComponent as KomunikacjaIcon} from "../../assets/icon/Reason/Komunikacja/Komunikacja.svg";
import colors from "../../theme/default";

const reasonsData = [
    {
        title: "Lata doświadczenia",
        description:
            "Posiadamy specjalistyczną wiedzę i doświadczenie w zakresie reklamy targetowanej, SEO oraz organizacji eventów, co pozwala nam tworzyć skuteczne kampanie reklamowe dla naszych klientów.",
        icon: ExpertizaIcon,
    },
    {
        title: "Kompleksowe podejście",
        description:
            "Oferujemy naszym klientom wachlarz usług, które są nastawione nie tylko na zapewnienie wsparcia marketingowego, ale także na maksymalizację wyników świadczonych przez nich usług.",
        icon: ZintegrowaneIcon,
    },
    {
        title: "Myślimy analitycznie",
        description:
            "Każde nasze działanie jest nastawione na wyniki, dlatego dokonujemy sprawozdań prowadzonych przez nas kampanii, by mieć pewność co do jakości naszych usług.",
        icon: AnalyticsIcon,
    },
    {
        title: "Potrafimy ze sobą rozmawiać",
        description:
            "Komunikacja jest dla nas najważniejsza, to dzięki niej rozumiemy siebie i naszych klientów oraz ich potrzeby, a w efekcie jesteśmy w stanie zaoferować najlepsze rozwiązania dla osiągnięcia celów naszych klientów",
        icon: KomunikacjaIcon,
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        top: "-40px",
        backgroundColor: "#fcfbff",
        padding: "82px 40px 0 40px",
        borderRadius: "40px 40px 0 0",
        py: 8,

        [theme.breakpoints.down("sm")]: {
            padding: "40px 20px 20px 20px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "100px 100px 100px 100px",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "100px 150px 100px 150px",
        },
    },
    reasonContainer: {
        display: "flex",
        width: "60%",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "right",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    hr: {
        width: "100%",
        height: "2px",
        backgroundColor: `${colors.mineShaft}`,
        border: "none",
        margin: "10px 0",
    },
    numberHr: {
        display: "flex",
        alignItems: "baseline",
    },
    boxView: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "15%",
        "&:hover": {
            "& hr": {
                backgroundColor: `${colors.smalt}`,
                transition: "background-color 0.5s ease-in-out",
            },
            "& .title": {
                color: `${colors.smalt}`,
                transition: "color 0.5s ease-in-out",
            },
            "& .counter": {
                color: `${colors.smalt}`,
                transition: "color 0.5s ease-in-out",
            },
            "& .icon-path": {
                fill: `${colors.smalt}`,
                transition: "fill 0.5s ease-in-out",
            },
        },
    },
    iconBox: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    icon: {
        width: "90%",
    },
    counter: {
        fontSize: "36px",
        fontFamily: "Syncopate",
        fontWeight: "bold",
        color: "#232323",
        marginRight: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
        },
    },
    title: {
        fontSize: "40px",
        fontFamily: "Montserrat",
        fontWeight: "400",
        lineHeight: "48px",
        marginBottom: "10px",
        color: "#232323",
        transition: "color 0.3s ease-in-out",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
        },
    },
    description: {
        fontSize: "20px",
        fontFamily: "Montserrat",
        fontWeight: "400",
        lineHeight: "24px",
        color: "#232323",
        letterSpacing: "4%",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
}));

const WhyChooseUsSection = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root} id="reason">
            <SectionWithLine
                title="Nasze mocne strony"
                lineColor="#003399"
                textSize="fit-content"
                textColor="#232323"
            />
            <Box>
                {reasonsData.map((reason, index) => {
                    const Icon = reason.icon;
                    return (
                        <Box key={index} className={classes.boxView}>
                            <Box className={classes.iconBox}>
                                <Icon className={`${classes.icon} icon`}/>
                            </Box>
                            <Box className={classes.reasonContainer}>
                                <Box className={classes.numberHr}>
                                    <Typography className={`${classes.counter} counter`}>
                                        {(index + 1).toString().padStart(2, "0")}
                                    </Typography>
                                    <hr className={classes.hr}/>
                                </Box>
                                <Typography className={`${classes.title} title`}>
                                    {reason.title}
                                </Typography>
                                <Typography className={classes.description}>
                                    {reason.description}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default WhyChooseUsSection;






