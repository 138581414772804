import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";

type CounterProps = {
    startValue: number;
    endValue: number;
    text?: string;
};

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: 'Syncopate',
        color: '#000',
        fontSize: '32px',
        fontWeight: 700,
        margin: '0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
}));

const Counter: React.FC<CounterProps> = ({ startValue, endValue, text }) => {
    const [count, setCount] = useState(startValue);
    const classes = useStyles();

    useEffect(() => {
        const difference = Math.abs(endValue - startValue);
        const interval = Math.max(1, Math.floor(700 / difference)); // Рассчитываем интервал обновления

        const timer = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount === endValue) {
                    clearInterval(timer);
                    return prevCount;
                } else {
                    const increment = prevCount < endValue ? 1 : -1;
                    return prevCount + increment;
                }
            });
        }, interval); // Используем рассчитанный интервал обновления

        return () => {
            clearInterval(timer); // Очистка таймера при размонтировании компонента
        };
    }, [startValue, endValue]);

    return (
        <p className={classes.text}>
            {text && <span>{text}: </span>}
            {count}
        </p>
    );
};

export default Counter;

