import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import LogoHorizontal from "../../../assets/icon/Logo/LogoHorizontal";
import {Box} from "@material-ui/core";
import ToggleMenu from "../ToggleMenu";
import ActiveCursor from "../../../assets/icon/cursor-active.svg";
import colors from "../../../theme/default";

const navLinksData = [
    {id: 'about', text: 'Twórca'},
    {id: 'services', text: 'Usługi'},
    {id: 'portfolio', text: 'Projekty'},
];

const useStyles = makeStyles((theme) => ({
    appBar: {
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(252, 251, 255, 0.7)',
        boxShadow: 'none',
        transition: "all 0.7s ease-out",
    },
    scrolled: {
        backgroundColor: `${colors.magnolia}`,
    },
    hidden: {
        transform: "translate(0, -90%)",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        padding: '20px 40px',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '20px 100px 20px 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '20px 150px 20px 150px'
        }
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    navButton: {
        color: `${colors.mineShaft}`,
        fontSize: '16px',
        fontFamily: 'Montserrat',
        textTransform: 'none',
        fontWeight: 400,
        fontHeight: '20px',
        padding: '16px',
        cursor: `url(${ActiveCursor}) 11 11, pointer`,

        '&:hover': {
            color: `${colors.smalt}`,
            cursor: `url(${ActiveCursor}) 11 11, pointer`,

        },

        [theme.breakpoints.up('lg')]: {
            fontSize: '20px'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '22px'

        }
    },
    accentButton: {
        marginLeft: 'auto',
        borderRadius: 10,
        backgroundColor: `${colors.smalt}`,
        padding: '17px 32px',
        textAlign: 'center',
        color: `${colors.magnolia}`,
        fontSize: '16px',
        fontFamily: 'Montserrat',
        textTransform: 'none',
        fontWeight: 400,
        fontHeight: '20px',
        border: `1px solid ${colors.smalt}`,
        cursor: `url(${ActiveCursor}) 11 11, pointer`,


        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,

            backgroundColor: 'transparent',
            border: `1px solid ${colors.smalt}`,
            color: `${colors.smalt}`,
        },

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

function Header() {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();
    const [scrolled, setScrolled] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [atTop, setAtTop] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
            setScrolled(currentScrollPos > 0);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos, visible]);



    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isAtTop = currentScrollPos < 10;
            setAtTop(isAtTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <AppBar
            position="fixed"
            className={`${classes.appBar} ${scrolled ? classes.scrolled : ''} ${!visible && classes.hidden}`}
        >
            <Toolbar className={classes.toolbar}>
                <Box>
                    <LogoHorizontal/>
                </Box>
                <Box className={classes.title}>
                    {navLinksData.map((link) => (
                        <Button
                            key={link.id}
                            className={classes.navButton}
                            onClick={() => scrollToSection(link.id)}
                        >
                            {link.text}
                        </Button>
                    ))}
                </Box>
                <Box>
                    <ToggleMenu/>
                    <Button className={classes.accentButton} onClick={() => scrollToSection('contact')}>
                        Kontakt
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;

