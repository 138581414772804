import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ActiveCursor from "../../../assets/icon/cursor-active.svg";
import colors from '../../../theme/default';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: '10px',
        backgroundColor: `${colors.smalt}`,
        padding: '20px 40px',
        color: `${colors.magnolia}`,
        fontFamily: 'Montserrat',
        fontSize: '16px',
        textTransform: 'none',
        boxShadow: 'none',
        border: `1px solid ${colors.smalt}`,
        cursor: `url(${ActiveCursor}) 11 11, pointer`,
        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,
            backgroundColor: 'transparent',
            border: `1px solid ${colors.smalt}`,
            color: `${colors.smalt}`,
            boxShadow: 'none',

        },
        '&:disabled': {
            backgroundColor: `${colors.smalt}`,
            color: `${colors.magnolia}`,
            opacity: '0.5',
            cursor: 'not-allowed',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 60px',
            fontSize: '14px',
        },
    },
    outlinedButton: {
        borderRadius: '10px',
        backgroundColor: 'transparent',
        padding: '20px 40px',
        color: `${colors.smalt}`,
        fontFamily: 'Montserrat',
        fontSize: '16px',
        textTransform: 'none',
        boxShadow: 'none',
        border: `1px solid ${colors.smalt}`,
        cursor: `url(${ActiveCursor}) 11 11, pointer`,
        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,
            backgroundColor: `${colors.smalt}`,
            color: `${colors.magnolia}`,
            boxShadow: 'none',

        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 60px',
            fontSize: '14px',
        },
    },
}));

const StyledButton = ({
                          type,
                          disabled,
                          text,
                          width,
                          onClick,
                          mt,
                          children,
                          padding,
                          borderRadius,
                          variant,
                      }) => {
    const classes = useStyles();

    const buttonClasses = variant === 'outlined' ? classes.outlinedButton : classes.button;

    return (
        <Button
            className={buttonClasses}
            type={type}
            disabled={disabled}
            style={{
                width: `${width}`,
                marginTop: `${mt}`,
                padding: `${padding}`,
                borderRadius: `${borderRadius}`,
            }}
            onClick={onClick}
            variant={variant === 'outlined' ? 'outlined' : 'contained'}
        >
            {text}
            {children}
        </Button>
    );
};

export default StyledButton;

