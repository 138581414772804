import React from 'react';
import './styles.css';
import 'swiper/css';
import {Box} from '@material-ui/core';
import SectionWithLine from '../UI/Title';
import Slider from '../UI/Slider';
import {makeStyles} from '@material-ui/core/styles';
import WDK from '../../assets/image/Projects/WDK/wdk-card.jpg';
//import colors from "../../theme/default";

// const projects = [
//     {
//         id: 0,
//         title: 'Hutsulka',
//         desc: 'Clothes web-store for Ukrainian brand Hutsulka.',
//         imgSrc: 'https://picsum.photos/id/235/400/300',
//         imgSrcForModal: ['https://picsum.photos/id/210/400/300', 'https://picsum.photos/id/211/400/300', 'https://picsum.photos/id/212/400/300', 'https://picsum.photos/id/213/400/300', 'https://picsum.photos/id/214/400/300'],
//     },
//     {
//         id: 1,
//         title: 'Project Name 1',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae mauris sed ipsum luctus gravida.',
//         imgSrc: 'https://picsum.photos/id/236/400/300',
//         imgSrcForModal: ['https://picsum.photos/id/210/400/300', 'https://picsum.photos/id/211/400/300', 'https://picsum.photos/id/212/400/300', 'https://picsum.photos/id/213/400/300', 'https://picsum.photos/id/214/400/300'],
//     },
//     {
//         id: 2,
//         title: 'Project Name 2',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae mauris sed ipsum luctus gravida. 1',
//         imgSrc: 'https://picsum.photos/id/238/400/300',
//         imgSrcForModal: ['https://picsum.photos/id/210/400/300', 'https://picsum.photos/id/211/400/300', 'https://picsum.photos/id/212/400/300', 'https://picsum.photos/id/213/400/300', 'https://picsum.photos/id/214/400/300'],
//     },
// ];

const designs = [
    {
        id: 0,
        title: 'Artificium Vaterum',
        desc: '',
        imgSrc: 'https://i.imgur.com/1QyVXi4.png',
        imgSrcForModal: ['https://i.imgur.com/wdM65wK.png', 'https://i.imgur.com/HMsEVpD.png', 'https://i.imgur.com/uqizSTk.png', 'https://i.imgur.com/f5OWoPM.png', 'https://i.imgur.com/CoIHQWd.png', 'https://i.imgur.com/NLA0yPa.png', 'https://i.imgur.com/98TfuI9.png', 'https://i.imgur.com/ktF0zIW.png', 'https://i.imgur.com/PIEZMtv.png', 'https://i.imgur.com/3oGItRn.png', 'https://i.imgur.com/lyiEh8r.png'],
    },
    {
        id: 1,
        title: 'Coffee House',
        desc: '',
        imgSrc: 'https://i.imgur.com/XEptytx.png',
        imgSrcForModal: ['https://i.imgur.com/eGqL1GD.jpg'],
    },
    {
        id: 2,
        title: 'Roamwell',
        desc: '',
        imgSrc: 'https://i.imgur.com/USDFa89.png',
        imgSrcForModal: ['https://i.imgur.com/PYsOENp.png', 'https://i.imgur.com/LV7y2BD.png', 'https://i.imgur.com/jzf8tTp.png', 'https://i.imgur.com/stTzdB4.png', 'https://i.imgur.com/LlsQ4S1.png', 'https://i.imgur.com/jjE8Wqp.png', 'https://i.imgur.com/ERj1VnF.png', 'https://i.imgur.com/IbKSLJK.png', 'https://i.imgur.com/N3GJk4R.png', 'https://i.imgur.com/ihU9mTl.png', 'https://i.imgur.com/9WEyrgj.png'],
    },
    {
        id: 3,
        title: 'Olympus',
        desc: '',
        imgSrc: 'https://i.imgur.com/nOQSNHm.png',
        imgSrcForModal: ['https://i.imgur.com/P2t9phN.png', 'https://i.imgur.com/MyY7VLS.png', 'https://i.imgur.com/ilEyZKl.png', 'https://i.imgur.com/3pE8coC.png', 'https://i.imgur.com/yxpRTt4.png', 'https://i.imgur.com/7MG4s6S.png', 'https://i.imgur.com/3Qgq7Qk.png', 'https://i.imgur.com/DLdNjfI.jpg', 'https://i.imgur.com/5R8a7WF.png', 'https://i.imgur.com/t5hVYZx.png'],
    },
    {
        id: 4,
        title: 'Stul',
        desc: '',
        imgSrc: 'https://i.imgur.com/bDKUZdW.png',
        imgSrcForModal: ['https://i.imgur.com/T3LmhNN.png', 'https://i.imgur.com/wwry7Yi.png', 'https://i.imgur.com/b2KlBr3.png', 'https://i.imgur.com/440kb4Y.png', 'https://i.imgur.com/oPy7xZo.jpg', 'https://i.imgur.com/bDKUZdW.png', 'https://i.imgur.com/7KjkAQc.png', 'https://i.imgur.com/i1wQloO.jpg'],
    },
    {
        id: 5,
        title: 'Woda dla Kujaw',
        desc: '',
        imgSrc: WDK,
        imgSrcForModal: ['https://i.imgur.com/2j8Zj3g.jpg'],
    },
    // {
    //     id: 6,
    //     title: 'LogoFolio',
    //     desc: '',
    //     imgSrc: 'https://i.imgur.com/6MMHiWf.jpg',
    //     imgSrcForModal: ['https://i.imgur.com/z2J8wXU.jpg'],
    // },
    // {
    //     id: 7,
    //     title: 'Redesign for Gaudeamus',
    //     desc: '',
    //     imgSrc: 'https://i.imgur.com/xy4SxGs.jpg',
    //     imgSrcForModal: ['https://i.imgur.com/zOIOP3v.jpg'],
    // },
    // {
    //     id: 8,
    //     title: 'Lunaware',
    //     desc: '',
    //     imgSrc: 'https://i.imgur.com/gRZxDPz.jpg',
    //     imgSrcForModal: ['https://i.imgur.com/3DsxkeD.jpg'],
    // },
    // {
    //     id: 9,
    //     title: 'CodeSmart',
    //     desc: '',
    //     imgSrc: 'https://i.imgur.com/t6FAIDP.jpg',
    //     imgSrcForModal: ['https://i.imgur.com/rbwpJHX.jpg'],
    // },
];

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '100px 40px 180px 40px',
        backgroundColor: '#232323',
        [theme.breakpoints.down('sm')]: {
            padding: '100px 20px 180px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 180px 100px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 150px 180px 150px',
        },
    },
    tabsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    tabs: {
        backgroundColor: 'transparent',
        color: '#f2f2f2',
        '& .MuiTabs-indicator': {
            backgroundColor: '#003399',
        },
    },
    tab: {
        fontSize: '14px',
        fontFamily: 'Syncopate-Regular',
        '&:hover': {
            color: '#003399',
            transition: '0.5s',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
}));

const Portfolio = () => {
    const classes = useStyles();
    //const [activeTab, setActiveTab] = useState(0);

    // const handleTabChange = (event, newValue) => {
    //     setActiveTab(newValue);
    // };

    return (
        <Box className={classes.root} id="portfolio">
            <SectionWithLine title="Projekty" lineColor="#FcFbFF" textColor="rgb(252, 251, 255)"/>
            {/*<Box className={classes.tabsContainer}>*/}
            {/*    <Tabs*/}
            {/*        value={activeTab}*/}
            {/*        onChange={handleTabChange}*/}
            {/*        indicatorColor="primary"*/}
            {/*        textColor={colors.magnolia}*/}
            {/*        className={classes.tabs}*/}
            {/*    >*/}
            {/*        <Tab label="Witryny internetowe" className={classes.tab}/>*/}
            {/*        <Tab label="Koncepcja projektu" className={classes.tab}/>*/}
            {/*    </Tabs>*/}
            {/*</Box>*/}
            {/*{activeTab === 0 && <Slider projects={projects} lineColor="#FcFbFF"/>}*/}
            {/*{activeTab === 1 && <Slider projects={designs} lineColor="#FcFbFF"/>}*/}
            <Slider projects={designs} lineColor="#FcFbFF"/>
        </Box>
    );
};

export default Portfolio;
