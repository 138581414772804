import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: props => props.marginBottom || '32px', // Задаем marginBottom с дефолтным значением 32px
        [theme.breakpoints.down('sm')]: {
            marginBottom: props => props.marginBottom || '20px', // Для мобильных устройств также задаем дефолтное значение 20px
        },
    },
    title: {
        fontFamily: 'Syncopate-Regular',
        textAlign: 'left',
        fontSize: '44px',
        paddingRight: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            paddingRight: '10px',
        },
    },
    line: {
        width: '100%',
    }
}));

const SectionWithLine = ({ title, lineColor, textSize, textColor, marginBottom }) => {
    const classes = useStyles({ marginBottom }); // Передаем значение marginBottom в useStyles
    return (
        <Box className={classes.root}>
            <Typography style={{ color: `${textColor}`, minWidth: `${textSize}` }} className={classes.title}>
                {title}
            </Typography>
            <hr style={{ border: `2px solid ${lineColor}` }} className={classes.line} />
        </Box>
    );
}

export default SectionWithLine;

