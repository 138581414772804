import React from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Counter from "../UI/Counter";
import colors from "../../theme/default";
import Typography from "@material-ui/core/Typography";
import StyledButton from "../UI/Button";
import AdbisBack from '../../assets/gif/Abstract_Shape_Background.gif';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '200px 40px 0 40px',
        height: '110vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${AdbisBack})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
            padding: '100px 20px 0 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 150px 0 150px'
        }
    },
    title: {
        fontFamily: 'Montserrat',
        color: `${colors.mineShaft}`,
        fontSize: '40px',
        textAlign: 'center',
        marginBottom: '60px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
    counterBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        [theme.breakpoints.down('sm')]: {
            gap: '10px',
            display: 'flex',
            flexDirection: 'row',
        },
    },
    counterGroup: {
        gap: '5%',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '60px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'flex-start',
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        gap: '32px',
    },
    counterInfo: {
        fontFamily: 'Montserrat',
        color: `${colors.mineShaft}`,
        fontSize: '22px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    blurCard: {
        borderRadius: '20px',
        padding: '40px',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(252, 251, 255, 0.7)',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        },
    },
}));



function Banner() {
    const classes = useStyles();
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.blurCard}>
                <Box>
                    <Typography className={classes.title}>
                        Widoczność w sieci to klucz do sukcesu
                    </Typography>
                </Box>
                <Box className={classes.counterGroup}>
                    <Box className={classes.counterBox}>
                        <Counter startValue={0} endValue={85} />
                        <Typography className={classes.counterInfo}>
                            Zrealizowanych projektów
                        </Typography>
                    </Box>
                    <Box className={classes.counterBox}>
                        <Counter startValue={0} endValue={10} />
                        <Typography className={classes.counterInfo}>
                            Partnerów biznesowych
                        </Typography>
                    </Box>
                    <Box className={classes.counterBox}>
                        <Counter startValue={0} endValue={7} />
                        <Typography className={classes.counterInfo}>
                            Lat doświadczenia
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.buttonWrapper}>
                    <StyledButton text="Kontakt" width="25%" padding="16px 20px" borderRadius="20px" onClick={() => scrollToSection('contact')}/>
                    <StyledButton text="Portfolio" width="25%" padding="16px 20px" borderRadius="20px" variant="outlined" onClick={() => scrollToSection('portfolio')}/>
                </Box>
            </Box>
        </Box>
    );
}

export default Banner;






