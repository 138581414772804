import React, {Component} from 'react';
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import {CssBaseline} from "@material-ui/core";
import Banner from "./components/Banner";
import About from "./components/About";
import Contact from "./components/Contact";
import Partners from "./components/Partners";
import Projects from "./components/Projects";
import Reason from "./components/Reason";
import ServiceBlock from "./components/Services";
import CookieModal from "./components/UI/CookieModal";

class App extends Component {

    // authenticate(){
    //   return new Promise(resolve => setTimeout(resolve, 300))
    // }
    //
    // componentDidMount(){
    //   this.authenticate().then(() => {
    //     const ele = document.getElementById('ipl-progress-indicator')
    //     if(ele){
    //       ele.classList.add('available')
    //       setTimeout(() => {
    //         ele.outerHTML = ''
    //       }, 300)
    //     }
    //   })
    // }
    render() {
        return (
            <>
                <CssBaseline />
                <Header />
                <Banner />
                <About />
                <ServiceBlock />
                <Projects />
                <Reason />
                <Partners />
                <Contact />
                <CookieModal />
                <Footer/>
            </>
        );
    };
}

export default App;
