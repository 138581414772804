import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Drawer, List } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './styles.css';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import StyledButton from "../../UI/Button";
import colors from "../../../theme/default";

const navLinksData = [
    {id: 'about', text: 'Twórca'},
    {id: 'services', text: 'Usługi'},
    {id: 'portfolio', text: 'Projekty'},
];

const useStyles = makeStyles((theme) => ({
    menuButton: {
        padding: '0',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    list: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px 20px 20px',
        justifyContent: 'space-between',

    },
    drawer: {
        width: '50%',
        height: '50%',
        backgroundColor: `${colors.mineShaft}`,
        borderRadius: '20px 0 0 20px',
    },
    accentButton: {
        marginLeft: 'auto',
        borderRadius: 10,
        backgroundColor: `${colors.smalt}`,
        padding: '10px 32px',
        textAlign: 'center',
        color: `${colors.magnolia}`,
        fontSize: '16px',
        fontFamily: 'Montserrat',
        textTransform: 'none',
        fontWeight: 400,
        width: '100%;',
        fontHeight: '20px',

        '&:hover': {
            backgroundColor: 'black',
        },
    },
    navBlock: {
        color: `${colors.magnolia}`,
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    navigationText: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: 400,
        color: '#fcfbff',
        textTransform: 'none',
        '&:hover': {
            color: `${colors.smalt}`,
        },
    },
    closeIcon: {
        color: `${colors.magnolia}`,
        fontSize: '30px',
        marginLeft: 'auto',
        '&:hover': {
            color: `${colors.smalt}`,
        },
    },
}));

function ToggleMenu() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <CloseIcon className={classes.closeIcon} onClick={toggleDrawer(false)} />
            <List className={classes.navBlock}>
                {navLinksData.map((link) => (
                    <Button key={link.id} onClick={() => scrollToSection(link.id)}>
                        <Typography className={classes.navigationText}>
                            {link.text}
                        </Typography>
                    </Button>
                ))}
            </List>
            <StyledButton type="button" text="Kontakt" width="100%" onClick={() => scrollToSection('contact')}/>
        </div>
    );

    return (
        <div>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)} classes={{paper: classes.drawer}}>
                {list()}
            </Drawer>
        </div>
    );
}

export default ToggleMenu;
