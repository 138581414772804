import React from "react";
import {Box, Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AvatarIcon from './avatar.png';
import AvatarModal from "../UI/ModalAvatar";
import SectionWithLine from "../UI/Title";
import colors from "../../theme/default/index";

const useStyles = makeStyles((theme) => ({
    aboutSection: {
        backgroundColor: `${colors.magnolia}`,
        borderRadius: '40px 40px 0 0',
        position: 'relative',
        top: '-40px',
        padding: '100px 40px 0 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '50px 20px 50px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 140px 0 140px'
        }
    },
    container: {
        padding: '0',
    },
    titleBlock: {
        fontFamily: 'Syncopate-Regular',
        textAlign: 'center',
        fontSize: '140px',
        color: `${colors.smalt}`,
        [theme.breakpoints.down("sm")]: {
            fontSize: '70px',
        }
    },
    subTitleBlock: {
        fontFamily: 'Syncopate-Regular',
        textAlign: 'right',
        fontSize: '80px',
        color: `${colors.smalt}`,
        position: 'relative',
        top: '-60px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '40px',
            top: '-25px',
        }
    },
    avatar: {
        width: theme.spacing(40),
        height: theme.spacing(40),
        margin: theme.spacing(0, 4, 0, 0),
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        }
    },
    img: {
        height: 650,
        objectFit: "cover",
        marginRight: theme.spacing(0),
        filter: "grayscale(100%)",
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        }
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        flexBasis: "50%",
        position: 'relative',
        top: '40px',
        // [theme.breakpoints.down("xs")]: {
        //     flexBasis: "100%",
        // },
    },
    aboutDescription: {
        //position: 'relative',
        //top: '-40px',
        width: '45%',
        [theme.breakpoints.down("sm")]: {
            position: 'relative',
            top: 'unset',
            width: '100%',
        }
    },
    descriptionText: {
        fontFamily: 'Montserrat',
        textAlign: 'left',
        fontSize: '20px',
        letter: '2%',
        [theme.breakpoints.down("sm")]: {
            fontSize: '16px',
        }
    },
    boxView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const descriptionTextAbout = `Cześć! Jestem założycielem firmy, która specjalizuje się w tworzeniu stron
                                    internetowych,
                                    organizowaniu eventów biznesowych oraz prowadzeniu kampanii promocyjnych. Od lat
                                    pasjonuję
                                    się branżą kreatywną i sukcesywnie rozwijam swoje umiejętności, aby dostarczać
                                    klientom
                                    wyjątkowe rozwiązania na rynku. Cenię sobie jakość i profesjonalizm, a moją misją
                                    jest
                                    pomaganie klientom w osiągnięciu sukcesu w biznesie.`;

function About() {
    const classes = useStyles();

    return (
        <Box className={classes.aboutSection} id="about">
            <SectionWithLine title="Twórca" lineColor={colors.smalt} textSize="fit-content" textColor="#232323"
                             marginBottom="0px"/>
            <Container className={classes.container}>
                <Box>
                    <Box className={classes.textContainer}>
                        <Box>
                            <Typography className={classes.titleBlock}>
                                Serhii
                            </Typography>
                            <Typography className={classes.subTitleBlock}>
                                Zinchenko
                            </Typography>
                        </Box>
                        <Box className={classes.boxView}>
                            <Box className={classes.aboutDescription}>
                                <Typography className={classes.descriptionText}>
                                    {descriptionTextAbout}
                                </Typography>
                                <AvatarModal/>
                            </Box>
                            <img src={AvatarIcon} alt="John Doe" className={classes.img}/>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default About;

