import React from 'react';
import {Container, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import colors from "../../../theme/default/index";


const useStyles = makeStyles((theme) => ({
    footer: {
        color: `${colors.mineShaft}`,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        letter: '0.15px',
        textAlign: 'center',
        padding: '10px',
    },
}));
const Footer = () => {
    const classes = useStyles();
    return (
        <div>
            <Container>
                <Typography className={classes.footer}>
                    © 2023 Wszelkie prawa zastrzeżone
                </Typography>
            </Container>
        </div>
    );
};

export default Footer;

