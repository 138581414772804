import React, {useState} from "react";
import {makeStyles, Theme, createStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {Box} from "@material-ui/core";
import StyledButton from "../Button";
import colors from "../../../theme/default";

interface Props {
    id: number;
    title: string;
    fullDesc: string;
    imgSrcForModal: string[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(12px)',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
        paper: {
            width: '90%',
            height: '90%',
            position: 'relative',
            padding: '40px 20px 20px 20px',
            outline: 'none',
            backgroundColor: `${colors.magnolia}`,
            borderRadius: '32px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%',
                borderRadius: '0',
                padding: '20px 10px 10px 10px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '90%',
                height: '90%',
            },
        },
        modalSubtitle: {
            marginBottom: theme.spacing(4),
        },
        modalCloseButton: {
            padding: '0',
            color: `${colors.mineShaft}`,
        },
        modalContent: {
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '0 0 32px 32px',
            padding: '20px 20px 24px',
            backgroundColor: `${colors.magnolia}`,
            textAlign: 'center',
        },
        img: {
            //marginBottom: '60px',
        },
        fullDescriptionText: {
            fontFamily: 'Montserrat',
            fontSize: '14px',
            color: `${colors.mineShaft}`,
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                overflow: 'scroll',
                maxHeight: '100%',
            },
        },
        imgScrollContainer: {
            borderRadius: '10px',
            maxHeight: '70vh',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                maxHeight: '85vh',
            },
        },
    })
);
const ModalComponent: React.FC<Props> = ({
                                             id,
                                             title,
                                             // fullDesc,
                                             imgSrcForModal
                                         }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <StyledButton type="button" text="Więcej" width="100%" onClick={handleOpen} mt="20px"/>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                aria-labelledby={`modal-title-${id}`}
                aria-describedby={`modal-description-${id}`}
            >
                <Box className={classes.paper}>
                    <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography id={`modal-title-${id}`} style={{
                            fontFamily: 'Syncopate-Regular',
                            fontSize: '20px',
                            color: `${colors.mineShaft}`,
                            fontWeight: '700'
                        }}>
                            {title}
                        </Typography>
                        <IconButton
                            className={classes.modalCloseButton}
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px'}}>
                        <Box className={classes.imgScrollContainer}>
                            {imgSrcForModal.map((src, index) => (
                                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                <img key={index} className={classes.img} src={src} alt={`Image ${index}`}/>
                                // <ImageLoader key={index} src={src} />
                            ))}
                        </Box>
                        {/*<Box>*/}
                        {/*    <Typography*/}
                        {/*        id={`modal-description-${id}`}*/}
                        {/*        gutterBottom*/}
                        {/*        className={classes.fullDescriptionText}*/}
                        {/*    >*/}
                        {/*        {fullDesc}*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        {/*<StyledButton type="button" text="Widze" onClick={handleClose} />*/}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ModalComponent;



