import React from "react";

function LinkedInLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            fill="none"
            viewBox="0 0 33 32"
        >
            <rect width="32" height="32" x="0.382" fill="#fff" rx="10"></rect>
            <path fill="#232323" d="M7.664 12.89h3.226v12.524H7.663V12.89z"></path>
            <mask
                id="mask0_61_1875"
                style={{ maskType: "luminance" }}
                width="21"
                height="20"
                x="6"
                y="6"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#fff" d="M6.382 6h20v20h-20V6z"></path>
            </mask>
            <g fill="#232323" mask="url(#mask0_61_1875)">
                <path d="M9.276 10.422a1.92 1.92 0 01-1.918-1.918 1.92 1.92 0 011.918-1.918 1.92 1.92 0 011.918 1.918 1.92 1.92 0 01-1.918 1.918zM22.18 25.414l-.005-7.33a2.502 2.502 0 00-2.499-2.5 2.502 2.502 0 00-2.498 2.5v7.33h-3.226V12.89h3.01v1.675l.635.004.116-.18a4.168 4.168 0 013.52-1.916c2.288 0 4.12 1.748 4.171 3.98l.001.054v8.907H22.18z"></path>
            </g>
        </svg>
    );
}

export default LinkedInLogo;