import React from 'react';
import {Box, Container, Grid, Typography, Card, CardContent, Link, IconButton} from '@material-ui/core';
import ContactForm from '../UI/ContactForm';
import './styles.css';
import {makeStyles} from "@material-ui/core/styles";
import FacebookLogo from "../../assets/icon/SocialMediaLogo/FB";
import InstagramLogo from "../../assets/icon/SocialMediaLogo/Instagram";
import LinkedInLogo from "../../assets/icon/SocialMediaLogo/Linkedin";
//import YouTubeLogo from "../../assets/icon/SocialMediaLogo/YouTube";
//import TikTokLogo from "../../assets/icon/SocialMediaLogo/TikTok";
import SectionWithLine from "../UI/Title";
import colors from "../../theme/default";
import ActiveCursor from "../../assets/icon/cursor-active.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: `${colors.mineShaft}`,
        padding: '100px 40px 100px 40px',
        borderRadius: '40px 40px 0 0',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 20px 40px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 120px 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 150px 120px 150px'
        }
    },
    mediaTitle: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: `${colors.magnolia}`,
        marginBottom: '10px',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    link: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    socialIcon: {
        color: theme.palette.common.white,
        padding: '0',
        cursor: `url(${ActiveCursor}) 11 11, pointer`,
        '&:hover': {
            cursor: `url(${ActiveCursor}) 11 11, pointer`,
        },
    },
    socialIconBox: {
        display: 'flex',
        margin: '12px 0 32px 0',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            gap: '10px',
        },
    },
    subtitle: {
        color: `${colors.magnolia}`,
        fontSize: '13px',
        fontWeight: '400',
        fontFamily: 'Montserrat',
        '&:hover': {
            color: `${colors.smalt}`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            flexWrap: 'nowrap',
        },
    },
    cardContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            gap: '40px',
        },
    },
    mailRedirect: {
        fontFamily: 'Montserrat',
        textAlign: 'left',
        fontWeight: 'bold',
        color: `${colors.magnolia}`,
        fontSize: '20px',
        lineHeight: '24px',
        textTransform: 'lowercase',
        textDecoration: 'none',
        '&:hover': {
            color: `${colors.smalt}`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    contactInfoBlock: {
        padding: '0',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column-reverse'
    },
    descriptionText: {
        fontFamily: 'Montserrat',
        textAlign: 'left',
        color: `${colors.magnolia}`,
        fontSize: '20px',
        lineHeight: '24px',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    serviceTitle: {
        fontFamily: 'Montserrat',
        textAlign: 'left',
        color: 'rgba(217,217,217,0.5)',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    titleContactFormBlock: {
        fontFamily: 'Montserrat',
        textAlign: 'left',
        color: `${colors.magnolia}`,
        fontSize: '32px',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    contactFormWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    textBox: {
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0',
        },
    }
}));

const socialLinks = [
    {
        href: "https://www.facebook.com/adbispl",
        icon: <FacebookLogo/>
    },
    {
        href: "https://www.instagram.com/adbis.pl/",
        icon: <InstagramLogo/>
    },
    {
        href: "https://www.linkedin.com/company/adbis/",
        icon: <LinkedInLogo/>
    },
    // {
    //     href: "https://www.youtube.com/",
    //     icon: <YouTubeLogo/>
    // },
    // {
    //     href: "https://www.tiktok.com/",
    //     icon: <TikTokLogo/>
    // },
];


function Contact() {
    const classes = useStyles();

    return (
        <Box className={classes.root} id="contact">
            <SectionWithLine title="Napisz do Nas" lineColor="#FcFbFF" textSize textColor="rgb(252, 251, 255)"/>
            <Container maxWidth="md">
                <Grid container spacing={2} sx={{height: '100%', padding: '0'}} className={classes.container}>
                    <Grid item xs={12} md={6}>
                        <Card style={{backgroundColor: 'transparent', height: '100%', marginTop: '10px'}}>
                            <CardContent className={classes.cardContent}>
                                <Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.descriptionText}>
                                            Pomożemy Ci szybciej rozwinąć twój biznes
                                        </Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        <Typography className={classes.descriptionText}>
                                            Wypełnij formularz lub napisz do nas na adres:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography>
                                            <a href="mailto:adbis.biuro@gmail.com" className={classes.mailRedirect}>
                                                adbis.biuro@gmail.com
                                            </a>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.contactInfoBlock}>
                                    <Box style={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography className={classes.mediaTitle}>
                                            Dane kontaktowe
                                        </Typography>
                                        <Typography className={classes.subtitle}>
                                            ul. Obrońców Bydgoszczy 1, 85-054 Bydgoszcz
                                        </Typography>
                                        <Link
                                            href="tel:+48727937366"
                                            className={classes.subtitle}
                                        >
                                            (+48) 727-937-366
                                        </Link>
                                        <Link
                                            href="mailto:adbis.biuro@gmail.com"
                                            className={classes.subtitle}
                                            variant="body2"
                                        >
                                            adbis.biuro@gmail.com
                                        </Link>
                                        <Link
                                            href="https://drive.google.com/file/d/11HQYLksadgMaGXe3DmrOt37ldEO1qQiv/view?usp=drive_link"
                                            className={classes.subtitle}
                                            variant="body2"
                                        >
                                            Polityka Prywatności
                                        </Link>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.mediaTitle}>
                                            Media społecznościowe
                                        </Typography>
                                        <Box className={classes.socialIconBox}>
                                            {socialLinks.map(({href, icon}) => (
                                                    <IconButton
                                                        key={href}
                                                        href={href}
                                                        target="_blank"
                                                        rel="noopener"
                                                        className={classes.socialIcon}
                                                    >
                                                        {icon}
                                                    </IconButton>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography className={classes.titleContactFormBlock}>
                                Jak możemy Ci pomóc?
                            </Typography>
                            <Typography className={classes.serviceTitle}>
                                Strony WWW/ SEO/ SMM/ Fotografia/ Filmowanie/ Event/ Szkolenia/ Tłumaczenia/
                            </Typography>
                        </Box>
                        <Card sx={{backgroundColor: 'transparent', height: '100%'}}>
                            <CardContent className={classes.contactFormWrapper}>
                                <ContactForm/>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    );
}

export default Contact;



